// export const DGTL_BACKEND_URL = "http://localhost:5000";
//commented
export const RISK_ENGINE_BACKEND_URL = "https://risk.dgtlbanking.com/api";
// export const RISK_ENGINE_BACKEND_URL = "http://localhost:8080/api";
export const DGTL_BACKEND_URL = "https://staging.digitaldeposits.app";
// export const DGTL_BACKEND_URL = "https://clients.digitaldeposits.app";
// export const DGTL_BACKEND_URL = "http://localhost:5000";
// export const DGTL_BACKEND_URL = "https://risk-engine-be.vercel.app";

export const REACT_APP_RISK_ENGINE_URL = "http://localhost:3001";
