import React, { useEffect, useState } from "react";

import "./DashBoard.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { CreateTab, UpdateTab } from "./store/actions/tabsActions";
import { useDispatch, useSelector } from "react-redux";

import toast from "react-hot-toast";
import warning from "./images/warning-red.png";
export const AddDetailModalComp = ({ show, onHide, Riskdata, tabs }) => {
  const [rows, setRows] = useState([{ id: 1, title: "", score: "", risk: "" }]);
  const dispatch = useDispatch();
  const [newItem, setNewItem] = useState({
    title: "" || Riskdata?.title,
    weight: "" || Riskdata?.weight,
    description: "" || Riskdata?.description,
  });
  const [formErrors, setFormErrors] = useState({
    title: false,
    weight: false,
    description: false,
  });
  const [rowerrors, setrowErrors] = useState([]);
  const { company } = useSelector((state) => state.user);

  const validaterowsForm = () => {
    const errors = rows.map((row) => {
      return {
        title: row.title.trim() === "",
        score: row.score.trim() === "",
      };
    });
    setrowErrors(errors);
    return errors.every((error) => error.title || error.score);
  };
  useEffect(() => {
    if (Riskdata) {
      setNewItem({
        title: Riskdata?.title || "",
        weight: Riskdata?.weight || "",
        description: Riskdata?.description || "",
      });
    }
  }, [Riskdata, show]);

  function sumOfWeights(jsonArray) {
    return jsonArray.reduce((total, current) => {
      return total + current.weight;
    }, 0);
  }
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];

    // Reset error if the input is filled
    if (value.trim() !== "") {
      setrowErrors({
        ...rowerrors,
        [name]: false,
      });
    }
    const updatedErrors = [...rowerrors];
    if (value.trim() !== "") {
      updatedErrors[index] = {
        ...updatedErrors[index],
        [name]: false,
      };
    }

    // Handle score input restriction and risk assignment
    if (name === "score") {
      if (value === "" || /^[1-5]$/.test(value)) {
        updatedRows[index][name] = value;
        const score = parseInt(value, 10);

        if (isNaN(score)) {
          updatedRows[index]["risk"] = "";
        } else if (score <= 2) {
          updatedRows[index]["risk"] = "Low";
        } else if (score == 3) {
          updatedRows[index]["risk"] = "Medium";
        }  else if (score == 4) {
          updatedRows[index]["risk"] = "High";
        }  else {
          updatedRows[index]["risk"] = "Very High";
        }
      }
    } else {
      updatedRows[index][name] = value;
    }
    setrowErrors(updatedErrors);
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    const hasEmptyFields = rows.some(
      (row) => row.title.trim() === "" || row.score.trim() === ""
    );

    if (hasEmptyFields) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        rowData: true,
      }));
    } else {
      const newRow = { id: rows.length + 1, title: "", score: "", risk: "" };
      setRows([...rows, newRow]);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        rowData: false,
      }));
    }
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const handleNewChange = (e) => {
    const { name, value } = e.target;

    setNewItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === "",
    }));
  };
  function excludeById(jsonArray, excludeId) {
    return jsonArray.filter((jsonObject) => jsonObject._id !== excludeId);
  }
  const handleSubmit = () => {
    const { title, weight, description } = newItem;

    const newFormErrors = {
      title: !title,
      weight: !weight,
      description: !description,
    };

    setFormErrors(newFormErrors);
    const rowsErroExist = validaterowsForm();
    console.log(rowsErroExist);
    if (rowsErroExist && !Riskdata) {
      return;
    }
    if (!Object.values(newFormErrors).includes(true)) {
      const payload = {
        title,
        description,
        companyId: company?._id,
        weight: parseFloat(weight), // Ensure weight is a number
        scores: rows.map(({ title, risk, score }) => ({
          title,
          risk,
          score: parseInt(score, 10), // Ensure score is a number
        })),
      };
      console.log(sumOfWeights(excludeById(tabs, Riskdata?._id)));
      console.log(parseFloat(weight));
      console.log(
        sumOfWeights(excludeById(tabs, Riskdata?._id)) + parseFloat(weight)
      );
      if (
        parseFloat(
          sumOfWeights(excludeById(tabs, Riskdata?._id)) + parseFloat(weight)
        ).toFixed(2) > 1
      ) {
        toast.error(
          `The total weight of risk factors is ${parseFloat(
            sumOfWeights(excludeById(tabs, Riskdata?._id)) + parseFloat(weight)
          ).toFixed(
            2
          )}, which exceeds the allowable limit of 1. Please adjust the sum of the weights to be 1.`
        );
      } else {
        if (
          parseFloat(
            sumOfWeights(excludeById(tabs, Riskdata?._id)) + parseFloat(weight)
          ).toFixed(2) < 1
        ) {
          toast((t) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img style={{ height: "40px", width: "40px" }} src={warning} />
              <span className="ms-3">
                The total weight of risk factors is{" "}
                {parseFloat(
                  sumOfWeights(excludeById(tabs, Riskdata?._id)) +
                    parseFloat(weight)
                ).toFixed(2)}
                , which is less than 1. Please ensure that the sum of the
                weights is correctly allocated.
              </span>
            </div>
          ));
        }
        if (Riskdata) {
          dispatch(UpdateTab(payload, company?._id, Riskdata?._id));
        } else {
          dispatch(CreateTab(payload, company?._id));
        }
        closeModal();
      }
    }
  };

  function closeModal() {
    setRows([{ id: 1, title: "", score: "", risk: "" }]);
    setrowErrors([]);
    setNewItem({
      title: "",
      weight: "",
      description: "",
    });
    setFormErrors({
      title: false,
      weight: false,
      description: false,
    });
    onHide();
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        closeModal();
      }}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{Riskdata ? "Edit Details" : "Add Details"}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
      >
        <form>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    formErrors.title ? "is-invalid" : ""
                  }`}
                  name="title"
                  id="title"
                  placeholder="Enter Your Title"
                  value={newItem.title}
                  onChange={handleNewChange}
                />
                {formErrors.title && (
                  <div className="invalid-feedback">
                    This field is required.
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="weight" className="form-label">
                  Weight
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    formErrors.weight ? "is-invalid" : ""
                  }`}
                  name="weight"
                  id="weight"
                  placeholder="Enter Your Weight"
                  value={newItem.weight}
                  onChange={handleNewChange}
                />
                {formErrors.weight && (
                  <div className="invalid-feedback">
                    This field is required.
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <textarea
                  className={`form-control ${
                    formErrors.description ? "is-invalid" : ""
                  }`}
                  name="description"
                  id="description"
                  rows="3"
                  value={newItem.description}
                  onChange={handleNewChange}
                ></textarea>
                {formErrors.description && (
                  <div className="invalid-feedback">
                    This field is required.
                  </div>
                )}
              </div>
            </div>
          </div>
          {!Riskdata ? (
            <div className="row">
              <div className="col-md-12">
                <div className="score-heading d-flex align-items-center justify-content-between">
                  <h5 className="text-primary mb-3">Score Heading</h5>
                  <Button
                    type="button"
                    className="btn btn-success mb-2"
                    onClick={handleAddRow}
                  >
                    Add
                  </Button>
                </div>
                <div className="table-sec-detail">
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Score</th>
                        <th>Risk</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, index) => (
                        <tr key={row.id}>
                          <td>{row.id}</td>
                          <td>
                            <input
                              type="text"
                              className={`form-control ${
                                rowerrors[index]?.title ? "is-invalid" : ""
                              }`}
                              name="title"
                              value={row.title}
                              placeholder="Enter Your Title"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                            {rowerrors[index]?.title && (
                              <div className="invalid-feedback d-block">
                                This field is required
                              </div>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              className={`form-control ${
                                rowerrors[index]?.score ? "is-invalid" : ""
                              }`}
                              name="score"
                              value={row.score}
                              placeholder="Enter Your Score"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                            {rowerrors[index]?.score && (
                              <div className="invalid-feedback d-block">
                                This field is required
                              </div>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="risk"
                              value={row.risk}
                              placeholder="Risk Result"
                              disabled
                              readOnly
                            />
                          </td>
                          <td>
                            <Button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => handleDeleteRow(index)}
                            >
                              <i className="bi bi-trash me-1"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
