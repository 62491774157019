import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "./TablePage.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCountriesScores,
  GetScores,
  DeleteScores,
  UpdateCountryScores,
  DefaultScore,
} from "./store/actions/scoreActions";
import toast from "react-hot-toast";
const CountryTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [data, setData] = useState(location.state ? location.state.data : []);
  const [editingIndex, setEditingIndex] = useState(null);
  const { countriesScores, scores } = useSelector((state) => state.score);
  const { user, company } = useSelector((state) => state.user);
  const [scoreData, setScoreData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setScoreData(countriesScores);
  }, [countriesScores]);

  const tabId = window.location.href.split("table/")[1];
  const [editingItem, setEditingItem] = useState({
    name: "",
    category: "",
  });
  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    if (!countriesScores && company) {
      dispatch(GetCountriesScores(company?._id));
    } else if (!company) {
      dispatch({ type: "LOAD_USER_REQUEST" });
    }
  }, [countriesScores, company]);

  useEffect(() => {
    if (tabId && user) {
      dispatch(GetScores(tabId));
    } else {
      dispatch({ type: "LOAD_USER_REQUEST" }); // Trigger the saga to fetch user data
    }
  }, [user]);

  const handleEdit = (item, index) => {
    setEditingIndex(index);
    setEditingItem({
      name: item?.name,
      category: item?.category,
    });
    setAddModal(true);
  };

  function getFieldScore(item) {
    const optionName = findFieldByString(item.category);
    console.log(optionName);
    const matchedOption = scores.scores[0]?.fields.find(
      (option) => option?.title === optionName
    );
    return matchedOption?.risk;
  }

  const handleUpdate = () => {
    console.log(editingItem);
    dispatch(UpdateCountryScores(editingItem, company?._id));
    setEditingIndex(null);
    setEditingItem({ name: "", category: "" });
    setAddModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedItem = { ...editingItem, [name]: value };
    setEditingItem(updatedItem);
  };

  function findFieldByString(searchString) {
    const keywords = {
      "Co-operative": "Co-operative Countries",
      "Non-Cooperative": "Non -Co-operative Countries/ML Jurisdictions",
      "Tax Haven": "Tax Haven countries which support terrorist activities",
      "Trade Sanctions": "Countries subject to Economic / Trade Sanctions",
      "Identified by FATF":
        "Countries identified by FATF as not having AML&CFT system",
      Corruption: "Countries on Corruption and Perception Index",
    };

    const matchedTitle = keywords[searchString];
    if (!matchedTitle) {
      console.log("not dounf");
      return searchString;
    }

    return matchedTitle;
  }

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
    // console.log(scores.scores[0].fields);
    const filtered = countriesScores?.[0]?.fields?.filter(
      (item) =>
        item.name.toLowerCase().includes(value) ||
        item.category.toLowerCase().includes(value)
    );
    const obj = [
      {
        createdAt: countriesScores?.[0]?.createdAt,
        fields: [...filtered],
        companyId: countriesScores?.[0]?.companyId,
        updatedAt: countriesScores?.[0]?.updatedAt,
        __v: countriesScores?.[0]?.__v,
        _id: countriesScores?.[0]?._id,
      },
    ];

    // console.log(obj);
    setScoreData(obj);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between p-4">
        <div style={{ width: "100%" }}>
          <h3>Countries</h3>
        </div>
        <div
          style={{ width: "100%" }}
          className="justify-content-end d-flex align-items-center"
        >
          <input
            type="search"
            className="form-control ms-4 me-2 search-risk-table"
            style={{ maxWidth: "300px" }}
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <Button
            type="button"
            className="btn btn-success"
            onClick={() => {
              setEditingItem({ title: "", risk: "", score: "" });
              setEditingIndex(null);
              setAddModal(true);
            }}
          >
            <i className="bi bi-plus-lg me-1"></i>Add Risk Parameter
          </Button>
        </div>
      </div>
      <div className="card card-body mx-4">
        <AddModalComp
          scores={scores?.scores}
          show={addModal}
          onHide={() => setAddModal(false)}
          onEdit={handleUpdate}
          editingItem={editingItem}
          isEditing={editingIndex !== null}
          handleChange={handleChange}
        />
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Risk</th>
              <th className="w-25">Actions</th>
            </tr>
          </thead>
          <tbody>
            {scoreData?.length > 0
              ? scoreData?.[0]?.fields?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name} </td>
                    <td>{item.category}</td>
                    <td>{getFieldScore(item)}</td>
                    <td>
                      <div className="d-flex align-items-center gap-3">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            handleEdit(item, index);
                          }}
                        >
                          <i className="bi bi-pencil me-1"></i>Edit
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
        <div className="text-center">
          <button
            type="button"
            className="btn btn-dark px-5 py-2"
            onClick={() => navigate("/dashboard")}
          >
            <i className="bi bi-arrow-left me-1"></i>Back
          </button>
        </div>
      </div>
    </>
  );
};

const AddModalComp = ({
  scores,
  show,
  onHide,
  onEdit,
  editingItem,
  isEditing,
  handleChange,
}) => {
  const [formErrors, setFormErrors] = useState({
    name: false,
    category: false,
  });

  function GetFieldRisk(optionName) {
    console.log(optionName);
    const matchedOption = scores?.[0]?.fields.find(
      (option) => option?.title === optionName
    );
    return matchedOption?.risk;
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? "Edit Score" : "Add Score"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  disabled
                  value={editingItem.name}
                  className={`form-control ${
                    formErrors.name ? "is-invalid" : ""
                  }`}
                  onChange={handleChange}
                  placeholder="Enter Your Country Name"
                />
                {formErrors.name && (
                  <div className="invalid-feedback">
                    This field is required.
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="mb-3">
                <label htmlFor="category" className="form-label">
                  Category
                </label>
                <select
                  name="category"
                  value={editingItem?.category}
                  onChange={handleChange}
                  class="form-select"
                  aria-label="Default select example"
                >
                  {scores &&
                    scores?.[0]?.fields?.map((d, i) => (
                      <option key={i} value={d.title}>
                        {d.title}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                disabled
                className={`form-control`}
                value={GetFieldRisk(editingItem.category)}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onEdit}>
          {isEditing ? "Save Changes" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CountryTable;
