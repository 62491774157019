import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Loader from "./Loader";
import DashBoard from "./DashBoard";
import TablePage from "./TablePage";
import ManageSetting from "./ManageSetting";
import MainLayout from "./shared/MainLayout";
import RiskTable from "./RiskTable";
import DetailsNew from "./DetailsNew";

import { Toaster } from "react-hot-toast";
import Forbidden from "./Forbidden";
import CountryTable from "./CountryTable";
const Redirector = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
      navigate("/details");
    }, 7000); // Show loader for 7 seconds

    return () => clearTimeout(timeout); // Clean up timeout on unmount
  }, [navigate]);

  if (loading) {
    return <Loader duration="5s" />;
  }

  return null;
};
// window.addEventListener("unload", function () {
//   // Clear all localStorage data
//   localStorage.clear();
// });

if (window.location.href.split("/")[4] && window.location.href.split("/")[5]) {
  console.log("sett");
  localStorage.setItem("token", window.location.href.split("/")[4]);
  localStorage.setItem("kycID", window.location.href.split("/")[5]);
}

const App = () => {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Router>
        <Routes>
          <Route path="/" element={<Redirector />} />
          <Route element={<MainLayout />}>
            {" "}
            {/* Layout route */}
            {/* <Route path="/kyc-details" element={<Details />} /> */}
            <Route path="/dashboard/*" element={<DashBoard />} />
            <Route path="/manage-setting" element={<ManageSetting />} />
            <Route path="/table/*" element={<TablePage />} />
            <Route path="/country-table/*" element={<CountryTable />} />
          </Route>
          <Route path="/details/*" element={<DetailsNew />} />
          <Route path="/risk-table" element={<RiskTable />} />
          <Route path="/forbidden" element={<Forbidden />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
