import { GET_SCORE_LIST , GET_COUNREIES_SCORE_LIST} from "../types";
import { RISK_ENGINE_BACKEND_URL } from "../URL";
import toast from "react-hot-toast";
const token =
window.location.href.split("/")[5] || localStorage.getItem("token");

export const GetScores = (tabId) => (dispatch) => {
  if (!token) {
    toast.error("Token not found. Please log in.");
    return;
  }
  fetch(`${RISK_ENGINE_BACKEND_URL}/getScores/?tabId=${tabId}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((res) => {
      dispatch({
        type: GET_SCORE_LIST,
        payload: res?.data,
      });
    })
    .catch((error) => {
      console.log(error);
      if (error?.response?.data?.error) {
        // toast.error(error?.response?.data?.error?.message);
      } else if (error.request) {
        // toast.error(error?.request);
      } else {
        // toast.error(error?.message);
      }
    });
};

export const GetCountriesScores = (companyId) => (dispatch) => {
  if (!token) {
    toast.error("Token not found. Please log in.");
    return;
  }
  fetch(`${RISK_ENGINE_BACKEND_URL}/countries-scores/${companyId}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((res) => {
      dispatch({
        type: GET_COUNREIES_SCORE_LIST,
        payload: res?.data,
      });
    })
    .catch((error) => {
      console.log(error);
      if (error?.response?.data?.error) {
        // toast.error(error?.response?.data?.error?.message);
      } else if (error.request) {
        // toast.error(error?.request);
      } else {
        // toast.error(error?.message);
      }
    });
};


export const UpdateCountryScores = (body, companyId) => (dispatch) => {
  if (typeof body !== "object" || body === null) {
    console.error("Invalid body:", body);
    return;
  }
  fetch(`${RISK_ENGINE_BACKEND_URL}/countries-scores/${companyId}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((data) => {
      toast.success(data?.message);
      dispatch(GetCountriesScores(companyId));
    })
    .catch((error) => console.error("Error:", error));
};

export const CreateScores = (body, tabId) => (dispatch) => {
  if (typeof body !== "object" || body === null) {
    console.error("Invalid body:", body);
    return;
  }
  fetch(`${RISK_ENGINE_BACKEND_URL}/createScores`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((data) => {
      toast.success(data?.message);
      dispatch(GetScores(tabId));
    })
    .catch((error) => console.error("Error:", error));
};

export const DeleteScores = (scoreId, fieldId, tabId) => (dispatch) => {
  fetch(
    `${RISK_ENGINE_BACKEND_URL}/deleteScoreFields/?scoreId=${scoreId}&fieldId=${fieldId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((data) => {
      toast.success(data?.message);
      dispatch(GetScores(tabId));
    })
    .catch((error) => console.error("Error:", error));
};

export const UpdateScores = (body, tabId) => (dispatch) => {
  if (typeof body !== "object" || body === null) {
    console.error("Invalid body:", body);
    return;
  }
  fetch(`${RISK_ENGINE_BACKEND_URL}/updateScoreFields`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((data) => {
      toast.success(data?.message);
      dispatch(GetScores(tabId));
    })
    .catch((error) => console.error("Error:", error));
};

export const DefaultScore = (scoreId, fieldId, tabId) => (dispatch) => {
  let body = {
    scoreId,
    fieldId,
  };
  fetch(`${RISK_ENGINE_BACKEND_URL}/makedefault`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((data) => {
      toast.success(data?.message);
      dispatch(GetScores(tabId));
    })
    .catch((error) => console.error("Error:", error));
};
