import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "./TablePage.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GetScores,
  CreateScores,
  DeleteScores,
  UpdateScores,
  DefaultScore,
} from "./store/actions/scoreActions";
import toast from "react-hot-toast";
const TablePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [data, setData] = useState(location.state ? location.state.data : []);
  const [editingIndex, setEditingIndex] = useState(null);
  const { scores } = useSelector((state) => state.score);
  const { user, company } = useSelector((state) => state.user);
  const [scoreData, setScoreData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    setScoreData(scores);
  }, [scores]);
  const tabId = window.location.href.split("table/")[1];
  const [editingItem, setEditingItem] = useState({
    title: "",
    risk: "",
    score: "",
  });
  const [addModal, setAddModal] = useState(false);

  const handleDelete = (scoreId, fieldId, tabId) => {
    dispatch(DeleteScores(scoreId, fieldId, tabId));
  };
  const handlDefault = (scoreId, fieldId, tabId) => {
    dispatch(DefaultScore(scoreId, fieldId, tabId));
  };
  useEffect(() => {
    if (tabId && user) {
      dispatch(GetScores(tabId));
    } else {
      dispatch({ type: "LOAD_USER_REQUEST" }); // Trigger the saga to fetch user data
    }
  }, [user]);

  const handleEdit = (scoreId, item, index) => {
    setEditingIndex(index);
    setEditingItem({
      title: item?.title,
      risk: item?.risk,
      score: item?.score,
      scoreId,
      fieldId: item?._id,
    });
    setAddModal(true);
  };

  const handleUpdate = () => {
    dispatch(UpdateScores(editingItem, tabId));
    setEditingIndex(null);
    setEditingItem({ title: "", risk: "", score: "" });
    setAddModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedItem = { ...editingItem, [name]: value };

    if (name === "score") {
      const numericValue = parseFloat(value);
      if (isNaN(numericValue) || numericValue < 1 || numericValue > 5) {
        // setFormErrors((prev) => ({ ...prev, score: true }));
      } else {
        updatedItem.risk = calculateRisk(numericValue);
        // setFormErrors((prev) => ({ ...prev, score: false }));
      }
    }

    setEditingItem(updatedItem);
  };

  const calculateRisk = (score) => {
    if (score >= 1 && score <= 2) {
      return "Low";
    } else if (score === 3) {
      return "Medium";
    } else if (score == 4) {
      return "High";
    } else if (score >= 5) {
      return "Very High";
    } else {
      return "";
    }
  };
  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
    // console.log(scores.scores[0].fields);
    const filtered = scores.scores[0]?.fields?.filter(
      (item) =>
        item.title.toLowerCase().includes(value) ||
        item.score.toString().includes(value) ||
        item.risk.toLowerCase().includes(value)
    );
    const obj = {
      scores: [
        {
          createdAt: scores.scores[0]?.createdAt,
          fields: [...filtered],
          tabId: scores.scores[0]?.tabId,
          updatedAt: scores.scores[0]?.updatedAt,
          __v: scores.scores[0]?.__v,
          _id: scores.scores[0]?._id,
        },
      ],
      tabDetail: { ...scores.tabDetail },
    };
    // console.log(obj);
    setScoreData(obj);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between p-4">
        <div style={{ width: "100%" }}>
          <h3>{scoreData?.tabDetail?.title}</h3>
        </div>
        <div
          style={{ width: "100%" }}
          className="justify-content-end d-flex align-items-center"
        >
          <input
            type="search"
            className="form-control ms-4 me-2 search-risk-table"
            style={{ maxWidth: "300px" }}
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          {scoreData?.tabDetail?.title?.includes("Country") && (
            <Button
              type="button"
              className="btn btn-primary m-3"
              onClick={() => {
                navigate(`/country-table/${tabId}`);
              }}
            >
              Change Country Score
            </Button>
          )}
          <Button
            type="button"
            className="btn btn-success"
            onClick={() => {
              setEditingItem({ title: "", risk: "", score: "" });
              setEditingIndex(null);
              setAddModal(true);
            }}
          >
            <i className="bi bi-plus-lg me-1"></i>Add Risk Parameter
          </Button>
        </div>
      </div>
      <div className="card card-body mx-4">
        <AddModalComp
          show={addModal}
          onHide={() => setAddModal(false)}
          onAdd={(newItem) => setData([...data, newItem])}
          onEdit={handleUpdate}
          editingItem={editingItem}
          isEditing={editingIndex !== null}
          handleChange={handleChange}
        />
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Title</th>
              <th>Score</th>
              <th>Risk</th>
              <th className="w-25">Actions</th>
            </tr>
          </thead>
          <tbody>
            {scoreData?.scores?.length > 0
              ? scoreData?.scores[0]?.fields?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.title}{" "}
                      {item.default ? (
                        <span class="badge badge-secondary ms-3">default</span>
                      ) : null}
                    </td>
                    <td>{item.score}</td>
                    <td>{item.risk}</td>
                    <td>
                      <div className="d-flex align-items-center gap-3">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            handleEdit(scoreData?.scores[0]?._id, item, index);
                          }}
                        >
                          <i className="bi bi-pencil me-1"></i>Edit
                        </button>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() =>
                            handleDelete(
                              scoreData?.scores[0]?._id,
                              item?._id,
                              tabId
                            )
                          }
                        >
                          <i className="bi bi-trash me-1"></i>Delete
                        </button>
                        <button
                          className="btn btn-info btn-sm"
                          onClick={() =>
                            handlDefault(
                              scoreData?.scores[0]?._id,
                              item?._id,
                              tabId
                            )
                          }
                        >
                          <i
                            style={{ fontSize: "15px" }}
                            className="bi bi-check me-1"
                          ></i>
                          Make Default
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
        <div className="text-center">
          <button
            type="button"
            className="btn btn-dark px-5 py-2"
            onClick={() => navigate("/dashboard")}
          >
            <i className="bi bi-arrow-left me-1"></i>Back
          </button>
        </div>
      </div>
    </>
  );
};

const AddModalComp = ({
  show,
  onHide,
  onAdd,
  onEdit,
  editingItem,
  isEditing,
  handleChange,
}) => {
  const [newItem, setNewItem] = useState({
    title: "",
    risk: "",
    score: "",
  });
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({
    title: false,
    risk: false,
    score: false,
  });
  const tabId = window.location.href.split("table/")[1];

  const handleNewChange = (e) => {
    const { name, value } = e.target;

    if (name === "score" && value.length > 1) {
      return;
    }

    const updatedItem = { ...newItem, [name]: value };

    if (name === "score") {
      const numericValue = parseFloat(value);
      if (isNaN(numericValue) || numericValue < 1 || numericValue > 5) {
        setFormErrors((prev) => ({ ...prev, score: true }));
      } else {
        updatedItem.risk = calculateRisk(numericValue);
        setFormErrors((prev) => ({ ...prev, score: false }));
      }
    } else if (name === "title" && value === "") {
      setFormErrors((prev) => ({ ...prev, title: true }));
    } else {
      setFormErrors((prev) => ({ ...prev, title: false }));
    }

    setNewItem(updatedItem);
  };

  const calculateRisk = (score) => {
    if (score >= 1 && score <= 2) {
      return "Low";
    } else if (score === 3) {
      return "Medium";
    } else if (score == 4) {
      return "High";
    } else if (score >= 5) {
      return "Very High";
    } else {
      return "";
    }
  };

  const handleAdd = () => {
    const { title, risk, score } = newItem;
    let valid = true;

    const newFormErrors = {
      title: !title,
      risk: !risk,
      score: !score,
    };

    if (Object.values(newFormErrors).includes(true)) {
      setFormErrors(newFormErrors);
      valid = false;
    } else {
      setFormErrors({ title: false, risk: false, score: false });
    }

    if (valid) {
      onAdd(newItem);
      let payload = {
        fields: [
          {
            title: newItem?.title,

            score: parseFloat(newItem?.score),
            risk: newItem?.risk,
          },
        ],

        tabId,
      };
      dispatch(CreateScores(payload, tabId));
      setNewItem({ title: "", risk: "", score: "" });
      onHide();
    }
  };

  const handleSubmit = () => {
    if (parseFloat(editingItem.score) <= 0) {
      toast.error("Score should be more than 0");
    } else if (parseFloat(editingItem.score) > 5) {
      toast.error("Score should be less than 5");
    } else if (isEditing) {
      onEdit();
    } else {
      handleAdd();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? "Edit Score" : "Add Score"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  value={isEditing ? editingItem.title : newItem.title}
                  className={`form-control ${
                    formErrors.title ? "is-invalid" : ""
                  }`}
                  onChange={isEditing ? handleChange : handleNewChange}
                  placeholder="Enter Your Title"
                />
                {formErrors.title && (
                  <div className="invalid-feedback">
                    This field is required.
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="score" className="form-label">
                  Score
                </label>
                <input
                  type="text"
                  name="score"
                  value={isEditing ? editingItem?.score : newItem.score}
                  onChange={isEditing ? handleChange : handleNewChange}
                  className={`form-control ${
                    formErrors.score ? "is-invalid" : ""
                  }`}
                  placeholder="Enter Your Score"
                />
                {formErrors.score && (
                  <div className="invalid-feedback">
                    This field is required and should be between 1 and 5.
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="risk" className="form-label">
                  Risk
                </label>
                <input
                  type="text"
                  name="risk"
                  onChange={isEditing ? handleChange : handleNewChange}
                  value={isEditing ? editingItem?.risk : newItem.risk}
                  className="form-control"
                  readOnly
                />
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {isEditing ? "Save Changes" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TablePage;
